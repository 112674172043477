import React from 'react';
import {getHref} from '../../../../../../domain/utils/itemUtils';
import {useControllerProps, UseControllerPropsResult} from '../../../../../../domain/controllers/ControllerContext';
import {STORES_APP_DEF_ID} from '@wix/wixstores-client-core';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export enum ItemLinkDataHooks {
  Anchor = 'ItemLinkDataHooks.Anchor',
  NonClickableItem = 'ItemLinkDataHooks.NonClickableItem',
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement>,
  navigationStore: UseControllerPropsResult['navigationStore'],
  urlPart: string,
  shouldUsePageUrlOnItemThumbnail: boolean,
  fullUrl: string
) => {
  event.preventDefault();
  event.stopPropagation();
  if (shouldUsePageUrlOnItemThumbnail && fullUrl) {
    return navigationStore.navigateToFullProductUrl(fullUrl);
  }
  return navigationStore.navigateToProduct(urlPart);
};

export const ItemLink = ({
  lineItem,
  children,
  tabIndex,
}: {
  lineItem: LineItemModel;
  children: React.ReactChild;
  tabIndex?: number;
}) => {
  const {cartStore, navigationStore} = useControllerProps();
  const {shouldUsePageUrlOnItemThumbnail} = cartStore;
  const href = getHref(cartStore, lineItem);
  const shouldRenderNonClickableItem = Boolean(
    ((lineItem.url.relativePath === '' || lineItem.catalogReference.appId !== STORES_APP_DEF_ID) &&
      !shouldUsePageUrlOnItemThumbnail) ||
      (shouldUsePageUrlOnItemThumbnail && lineItem.url.relativePath === '' && !lineItem.url.url)
  );

  return shouldRenderNonClickableItem ? (
    <div data-hook={ItemLinkDataHooks.NonClickableItem}>{children}</div>
  ) : (
    <a
      href={href}
      onClick={(event) =>
        handleClick(
          event,
          navigationStore,
          lineItem.url.relativePath,
          shouldUsePageUrlOnItemThumbnail,
          lineItem.url.url
        )
      }
      {...(tabIndex !== undefined ? {tabIndex} : {})}
      data-hook={ItemLinkDataHooks.Anchor}>
      {children}
    </a>
  );
};
