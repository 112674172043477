import React from 'react';
import s from '../../CartItem.scss';
import {CartItemDataHook} from '../../CartItem';
import {LineItemModel} from '../../../../../../domain/models/LineItem.model';

export const Price = ({
  lineItem,
  shouldUseChevronForItemOptions,
}: {
  lineItem: LineItemModel;
  shouldUseChevronForItemOptions: boolean;
}) => {
  const crossedOutPrice =
    lineItem.priceBeforeDiscount.formattedConvertedAmount || lineItem.fullPrice.formattedConvertedAmount;
  return (
    <div className={shouldUseChevronForItemOptions ? s.prices : /* istanbul ignore next */ s.pricesOld}>
      {crossedOutPrice && (
        <span
          data-hook={CartItemDataHook.ComparePrice}
          data-wix-line-full-item-price={CartItemDataHook.ComparePrice}
          className={s.comparePrice}>
          {crossedOutPrice}
        </span>
      )}
      {lineItem.price.formattedConvertedAmount && (
        <span data-hook={CartItemDataHook.Price} className={s.price} data-wix-line-item-price={CartItemDataHook.Price}>
          {lineItem.price.formattedConvertedAmount}
        </span>
      )}
    </div>
  );
};
